// withSubscribeModal.hoc.tsx
import React, { useState, ComponentType, PropsWithChildren, useEffect } from 'react';
import { minutes } from '@magic-poseidon/common/src/utils/time';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CButton from '../CButton/CButton.component';
import { useWaitlist } from '../../data/context/Waitlist.context';
import { sendEvent } from '../../integrations/events';
import { events } from '../../constants';
import CModal from './CModal.component';

export type WithModalProps = {
  onClick?: () => void;
};

const withSubscribeModal = <P extends WithModalProps>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  return (props: PropsWithChildren<P>) => {
    const { t } = useTranslation('common-ui');
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [lastWarmupTime, setLastWarmupTime] = useState(0);
    const { join, warmup, error, success } = useWaitlist();

    const handleOpen = () => {
      const currentTime = Date.now();
      const timeDiff = currentTime - lastWarmupTime;
      if (timeDiff > minutes(15)) {
        warmup();
        setLastWarmupTime(currentTime);
      }

      setOpen(true);
      sendEvent(events.WAITLIST_OPEN_MODAL);
    };

    const handleClose = () => {
      setEmail('');
      setOpen(false);
      sendEvent(events.WAITLIST_CLOSE_MODAL);
    };

    useEffect(() => {
      if (success) {
        setShowSuccess(true);
        sendEvent(events.WAITLIST_SUBMIT);
      }
    }, [success]);

    const handleSubscribe = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!email) return;

      await join(email);
    };

    return (
      <div>
        <WrappedComponent onClick={handleOpen} {...props} />
        <CModal onClose={handleClose} visible={open}>
          <div
            style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
          }}
          >
            {showSuccess ? (
              <div>
                <Typography color='secondary' variant='h6' align='center' gutterBottom>
                  {t('successfullyJoinedWaitlist')}
                </Typography>
                <Typography color='secondary' variant='subtitle1' align='center'>
                  {t('notifyWhenAvailable')}
                </Typography>
                <div style={{ marginTop: theme.spacing(2), textAlign: 'center' }}>
                  <CButton onClick={handleClose} variant='contained' color='primary'>
                    {t('close')}
                  </CButton>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubscribe}>
                <TextField
                  autoFocus
                  margin='dense'
                  id='name'
                  label={t('emailAddress')}
                  type='email'
                  placeholder={t('enterEmailAddress')}
                  fullWidth
                  value={email}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <code
                  style={{
                    color: theme.palette.common.black,
                    marginBottom: theme.spacing(3),
                    marginTop: theme.spacing(3),
                    fontSize: '0.7rem'
                  }}
                >
                  {t('joinWaitlistConsent')}
                </code>
                <div style={{ marginTop: theme.spacing(2) }}>
                  <CButton onClick={handleClose} style={{ margin: theme.spacing(1) }}>
                    {t('cancel')}
                  </CButton>
                  <CButton type='submit' variant='contained' color='primary'>
                    {t('joinWaitlist')}
                  </CButton>
                </div>
                {error && (
                  <Typography
                    align='center'
                    variant='body2'
                    color='error'
                    style={{ marginTop: theme.spacing(1) }}
                  >
                    {t('error')}: {error}
                  </Typography>
                )}
              </form>
            )}
          </div>
        </CModal>
      </div>
    );
  };
};

export default withSubscribeModal;
