export const events = {
  WAITLIST_OPEN_MODAL: {
    category: 'waitlist',
    action: 'close'
  },
  WAITLIST_CLOSE_MODAL: {
    category: 'waitlist',
    action: 'open'
  },
  WAITLIST_SUBMIT: {
    category: 'waitlist',
    action: 'submit'
  }
};
