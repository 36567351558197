import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import Loading from '@magic-poseidon/common-ui/src/components/CLoading/CLoading.component';
import Benefits from '@magic-poseidon/common-ui/src/components/Benefit/BenefitList.component';
import { BenefitProps } from '@magic-poseidon/common-ui/src/components/Benefit/Benefit.component';
import Hero from '@magic-poseidon/common-ui/src/components/CHero/CHero.component';
import Featured from '@magic-poseidon/common-ui/src/components/CFeatured/CFeatured.component';
// import Team from '@magic-poseidon/common-ui/src/components/CTeam/CTeam.component';
import Box from '@material-ui/core/Box';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import FeaturedVideo from '../videos/featured.mp4';
import PromoVideo from '../videos/promo_video.mp4';
import { TRANSLATION_NAMESPACE } from '../constants';
// import type { TeamImages } from '../types';

const FeaturedImage = (
  <StaticImage
    src='../images/player.svg'
    alt='Featured'
    className='type'
  />
);

const FeaturedImageMobile = (
  <StaticImage
    src='../images/player_mobile.svg'
    alt='Featured '
    className='type'
  />
);

// const teamImages: TeamImages = {
//   'lia-reid': <StaticImage
//     src='../images/Lia_Reid.jpeg'
//     alt='Featured '
//     className='type'
//   />,
//   'damian-borek': <StaticImage
//     src='../images/Damian_Borek.jpeg'
//     alt='Featured '
//     className='type'
//   />
// };

const IndexPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  // const teamMembers = t('teamMembers', { returnObjects: true }) as any[];

  const heroContent = {
    'header-p1': t('joinNow'),
    'header-p2': t('createRaffle'),
    description: t('experienceThrill'),
    'primary-action': t('joinWaitlist')
  };

  // const teamContent = {
  //   header: t('teamHeader'),
  //   description: t('teamDescription'),
  //   members: Array.isArray(teamMembers)
  //     ? teamMembers.map((member: any) => {
  //         return {
  //           ...member,
  //           image: member.id && teamImages[member.id]
  //         };
  //       })
  //     : []
  // };

  const benefitsContent: Array<BenefitProps> = [
    {
      heading: t('benefit1Heading'),
      headerP1: t('benefit1HeaderP1'),
      headerP2: t('benefit1HeaderP2'),
      description: t('benefit1Desc'),
      button: t('getChanceWinNFT'),
      image: (
        <StaticImage
          src={'../images/conversation.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row'
    },
    {
      heading: t('benefit2Heading'),
      headerP1: t('benefit2HeaderP1'),
      headerP2: t('benefit2HeaderP2'),
      description: t('benefit2Desc'),
      button: t('createRaffleGame'),
      image: (
        <StaticImage
          src={'../images/knowledge.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row-reverse'
    },
    {
      heading: t('benefit3Heading'),
      headerP1: t('benefit3HeaderP1'),
      headerP2: t('benefit3HeaderP2'),
      description: t('benefit3Desc'),
      button: t('benefit3CTA'),
      image: (
        <StaticImage
          src={'../images/customer_service.png'}
          formats={['auto', 'webp', 'avif']}
          alt={t('logoAltText')}
          className='type'
        />
      ),
      direction: 'row'
    }
  ];

  return (
    <Layout>
      <div style={{ width: '100%' }}>
        <Box>
          <Hero content={heroContent} />
        </Box>
        <Box>
          <Featured
            videoSource={FeaturedVideo}
            videoSourceOverlay={PromoVideo}
            image={FeaturedImage}
            mobileImage={FeaturedImageMobile}
          />
        </Box>
        <Box>
          <Benefits data={benefitsContent} />
        </Box>
        {/* <Box>
          <Team content={teamContent} />
        </Box> */}
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title={'Home'} />;

export default IndexPage;
