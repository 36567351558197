import React, { ReactNode } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { CButtonStyleType } from '../CButton/CButton.component';
import SubscribeModalButton from '../CButton/SubscribeModalButton.component';
import type { CustomTheme } from '../../core';

const useStyles = makeStyles((theme: CustomTheme) => ({
  marginBottomSpacingTwo: {
    marginBottom: theme.spacing(2)
  },
  marginBottomSpacingFour: {
    marginBottom: theme.spacing(4)
  },
  headerTop: {
    background: 'linear-gradient(266.83deg, #673FD7 -0.83%,'
    + '#5643CC 22.36%, #455EB5 68.23%, #455EB5 98.12%), #673FD7',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  headerP1: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  headerP2: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  buttonContent: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));

export interface BenefitProps {
  heading: string;
  headerP1: string;
  headerP2: string;
  description: string;
  button: string;
  image: ReactNode;
  direction: 'row' | 'row-reverse';
}

const Benefit: React.FC<BenefitProps> = ({
  heading,
  headerP1,
  headerP2,
  description,
  button,
  image,
  direction
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const whiteColor = theme.palette?.common?.white;

  const onClick = () => {
    window.open(process.env.GATSBY_DISCOVERY_CALL_FORM_URL || '', '_blank');
  };

  return (
    <Container maxWidth='lg'>
      <Grid container direction={direction}>
        <Grid item xs={12} md={5}>
          <Box textAlign='center' color='common.white'>
            <Typography className={`${classes.marginBottomSpacingTwo} ${classes.headerTop}`} align='left'>
              <span>
                {heading}
              </span>
            </Typography>
            <Typography className={classes.headerP1} align='left'>
              <span>
                {headerP1}
              </span>
            </Typography>
            <Typography className={`${classes.marginBottomSpacingFour} ${classes.headerP2}`} align='left'>
              <span>
                {headerP2}
              </span>
            </Typography>
            <Typography variant='subtitle1' align='left' color='secondary' paragraph={true}>
              {description}
            </Typography>
            <Box mt={4} className={classes.buttonContent}>
              <SubscribeModalButton
                styleType={CButtonStyleType.Dark}
                endIcon={<NavigateNext htmlColor={whiteColor} />}
              >
                <Typography style={{ color: whiteColor }}>{button}</Typography>
              </SubscribeModalButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={false} md={1}></Grid>
        <Grid item xs={12} md={6}>
          <Box mt={{ xs: 4, md: 0 }}>
            {image}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Benefit;
