import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Benefit, { BenefitProps } from './Benefit.component';

const useStyles = makeStyles(theme => ({
  styledSection: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  styledBox: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4)
    }
  }
}));

interface BenefitsProps {
  data: BenefitProps[];
}

const BenefitsList: React.FC<BenefitsProps> = ({ data }) => {
  const classes = useStyles();

  const benefits = data.map((params, index) => (
    <Box key={index} className={classes.styledBox}>
      <Benefit {...params} />
    </Box>
  ));

  return (
    <section className={classes.styledSection}>
      <Container>
        <Box>{benefits}</Box>
      </Container>
    </section>
  );
};

export default BenefitsList;
