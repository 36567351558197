// Fix controls for chrome
import React from 'react';

export interface VideoProps {
  videoSource: string;
  className?: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
}

const Video: React.FC<VideoProps> = props => {
  const {
  videoSource,
  className,
    autoPlay = true,
    muted = true,
    loop = true,
    controls = false
  } = props;

  return (
    <video
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      height='auto'
      className={className}
      data-testid='video'
      controls={controls}
    >
      <source src={videoSource} type='video/mp4' data-testid='video-source' />
    </video>
  );
};

Video.defaultProps = {
  autoPlay: true,
  muted: true,
  loop: true
};

export default Video;
